<template>
  <div id="home">
    <!-- banner部分 -->
    <a-carousel arrows autoplay class="banner">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 50px;zIndex: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right:50px">
          <right-circle-outlined />
        </div>
      </template>
      <div @click="toProductItem(1)"><img src="@/assets/img/banner1.png"></div>
      <div><img src="@/assets/img/banner2.png"></div>
      <div><img src="@/assets/img/banner3.png"></div>
      <!-- <div><img alt="Vue logo" src="" style="width: 100%"></div>
      <div><img alt="Vue logo" src="" style="width: 100%"></div> -->
    </a-carousel>
    <!-- 产品推荐 -->
    <section class="suggest">
      <a-row>
        <a-col :span="24" style="font-size: 24px;">
          <div class="suggest_h">
            <p style="font-size: 40px;font-weight: 700;margin-bottom: 0;">产品推荐</p>
            <div class="suggest_h_en">
              <p>PDODUCT</p>
              <p>RECOMMENDATION</p>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row class="product-eg">
        <a-col :span="6" class="flex_end">
          <div class="eg" style="position: relative;left: 10px;">
            <div class="hover_top">
              <div class="img"><img src="@/assets/img/icon6.png" alt=""></div>
              <p>
                远程识别系列
              </p>
            </div>
            <div class="hover">
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;"
                @click="toProductDetail(1)">
                无人机远程识别模块（嵌入式）
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(2)">
                无人机远程识别外挂模块
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(3)">
                无人机远程识别地面站
              </p>
            </div>
          </div>
        </a-col>
        <a-col :span="6" class="flex_end">
          <div class="eg">
            <div class="hover_top">
              <div class="img"><img src="@/assets/img/icon2.png" alt=""></div>
              <p>
                低空管控系列
              </p>
            </div>
            <div class="hover">
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;"
                @click="toProductDetail(4)">
                无人机AIS数据采集模块
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(5)">
                无人机避碰模块
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(6)">
                单兵飞机信号接收模块
              </p>
            </div>
          </div>
        </a-col>
        <a-col :span="6" class="flex_end">
          <div class="eg">
            <div class="hover_top">
              <div class="img"><img src="@/assets/img/icon1.png" alt=""></div>
              <p>
                船舶管控系列
              </p>
            </div>
            <div class="hover">
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;"
                @click="toProductDetail(7)">
                AIS射频信号发射模组
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(8)">
                AIS数据采集模块
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(9)">
                AIS个人便携应急数位标
              </p>
            </div>
          </div>
        </a-col>
        <a-col :span="6" class="flex_end">
          <div class="eg">
            <div class="hover_top">
              <div class="img"><img src="@/assets/img/icon4.png" alt=""></div>
              <p>
                智慧城市系列
              </p>
            </div>
            <div class="hover">
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;"
                @click="toProductDetail(10)">
                勤务数字化定位系统（移动端）
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(11)">
                非机动车智能管控
              </p>
              <p style="border: 1px solid #fff;font-size: 14px;font-weight: 500;width: 260px;height: 42px;line-height: 42px;margin-top: 20px;"
                @click="toProductDetail(12)">
                工牌记录仪
              </p>
            </div>
          </div>
        </a-col>
      </a-row>
    </section>
    <!-- 公司描述 -->
    <section class="product">
      <div class="gutter-row company_h">
        <div class="product_h">
          <p style="font-size: 40px;font-weight: 700;margin: 0;">忆伯科技</p>
          <p style="font-size: 24px;font-weight: 600;margin: 0; margin-left: 10px;">E-PRO</p>
        </div>
        <p style="font-size: 24px; font-weight: 800;margin-top: 40px;">天地空系统 智慧未来</p>
        <p style="font-weight: 400;margin-top: 33px;">
          上海忆伯科技股份有限公司（300468）成立于2016年，是专精特新、高新技术及上海股权托管交易中心科创板挂牌企业。作为技术方案驱动型公司，主要通过公共数据抓取、分析、中后台数据应用创新手段，依托网格可视化的技术应用方案耕耘于智慧警务、智慧城市及航海航天板块。同时，通过多年的经营管理及技术累积收获知识产权和资质共三十余项，收获荣誉包括：智慧城市联合实验室、公共数据应用创新中心、上海市信息安全行业协会理事单位、第一届上海市无人机安全管理协会会员单位等。
        </p>
        <p style="font-weight: 400;margin-top: 10px;">
          忆伯科技自主研发的ADS-B、AIS系统，和相配套的无人载具自动避碰方案广泛应用于各个航空航天及海事单位，主要包括：中船重工、中国航天、航天科工、自然资源部第一海洋研究所、中国科学院海洋研究所，中电科，中国海事局，西安飞机设计研究所等。
        </p>
      </div>
      <div class="gutter-row company-right">
        <div class="commany-img">
          <img src="@/assets/img/desc.jpg" alt="">
        </div>
      </div>
    </section>
    <!--新闻  -->
    <section class="news">
      <div style="display: flex;">
        <div class="news_title">
          <div class="news_h">
            <p style="font-size: 40px;font-weight: 700;margin-bottom: 0;">新闻动态</p>
            <div class="news_h_en">
              <p>NEWS</p>
              <p>UPDATE</p>
            </div>
          </div>
          <div class="news_more">
            <p style="font-size: 28px;font-weight: 700;">最新资讯</p>
            <p style="font-size: 16px;font-weight: 500;display: flex;align-items: center;">
              <router-link to="/news">查看更多
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="news_img">
        <div class="img-box">
          <img src="@/assets/img/new17.png" alt="" @click="toNewDetail(17)" style="cursor: pointer;">
          <img src="@/assets/img/new19.png" alt="" style="margin-top: 20px;cursor: pointer;" @click="toNewDetail(19)">
        </div>
        <div @click="toNewDetail(25)"><img src="@/assets/img/article/a8.png" alt=""
            style="width: 620px;height: 350px;padding-left: 20px; cursor:pointer;background-size: cover">
        </div>
        <div class="news-content">
          <div v-for="(item, index) in list" :key="index" class=" news-detail" @click="toNewDetail(item.id)">
            <p>{{ item.date.slice(8) }}</p>
            <p>{{ item.date.substring(0, 7) }}</p>
            <div class="right">
              <arrow-right-outlined :style="{ fontSize: '24px' }" />
            </div>
            <p class="title">{{ item.title }}</p>
            <p class="info">{{ item.des }}</p>
            <a-divider class="divider" />
          </div>
          <!-- <div class=" news-detail" @click="toNewDetail(2)">
                  <p>31</p>
                  <p>2023/03</p>
                  <div class="right">
                      <arrow-right-outlined :style="{ fontSize: '24px' }" />
                  </div>
                  <p>区镇领导来访忆伯科技参观交流</p>
                  <p>董事长徐伯胜向杨行镇镇领导介绍忆伯科技的产品设备的性能、行业应用情况和发展方向。</p>
              </div> -->
        </div>
      </div>
    </section>
    <!-- 回到顶部 -->
    <div>
      <a-back-top />
    </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined, ArrowRightOutlined } from '@ant-design/icons-vue';
import news from "@/api/test";

export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
    ArrowRightOutlined,
  },
  name: "Home",
  data() {
    return {
      list: news.slice(0, 2),
    }
  },
  methods: {
    toNewDetail(id) {
      this.$router.push({
        path: "/newsDetail",
        query: { //query是个配置项
          id: id
        }
      })
    },
    toProductItem(id) {
      console.log(333)
      this.$router.push({
        path: "/productItem",
        query: { //query是个配置项
          id: id
        }
      })
    },
    toProductDetail(id) {
      this.$router.push({
        path: "/productDetail",
        query: { //query是个配置项
          tab: id
        }
      })
    },
  },
})
</script>

<style scoped>
a {
  text-decoration: none;
  color: #333;
}

a:hover,
a:visited,
a:link,
a:active {
  color: #fff;
  /* 设置使所有a标签的四种状态都和本身颜色保持一致，样式代码自己写 */
}

/* p {
    margin: 0;
} */

.ant-carousel :deep(li.slick-active) {
  width: 80px !important;
}

.ant-carousel :deep(li) {
  width: 50px !important;
}

/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  /* height: 820px; */
  /* line-height: 820px; */
  background: #686e82;
  overflow: hidden;
}

.ant-carousel :deep(.slick-dots-bottom) {
  bottom: 60px;
}

.ant-carousel :deep(.custom-slick-arrow) {
  width: 45px;
  height: 45px;
  font-size: 45px;
  color: #fff;
  /* background-color: rgba(31, 45, 61, 0.11); opacity: 0.8; */
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-slide) {
  /* height: calc(100vh - 80px); */
}

.product {
  /* padding: 50px 100px 0px 100px; */
  padding: 0 300px;
  height: 680px;
  background-color: #1c1f2b;
  color: #fff;
  min-width: 1280px;
  width: 100%;
  /* overflow: hidden; */
  background-image: url(@/assets/product/bg2.png);
  background-size: cover;
  display: flex;
  justify-content: space-between;
}

/* 公司描述 */
.company_h {
  width: 1000px;
  margin-top: 250px;
}

.company_h p {
  font-size: 14px;
  text-align: justify;
}

.company_h .title {
  font-size: 14px;
  text-align: justify;
}

.company_h .product_h {
  display: flex;
  height: 60px;
  align-items: end;
}

.company_h .product_h p {
  /* display: flex;
  height: 60px;
  align-items: end;
  margin-top: 135px; */

}

.company-right {
  display: flex;
  justify-content: end;
}

.banner {
  width: 100vw;
  /* min-width: 1280px; */
  /* height: 100%; */
  height: calc(100vw * 0.51);
  position: relative;
}

.banner img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
  /* min-height: 820px; */
  /* object-fit: cover; */
}

.commany-img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 680px;
  height: 480px; */
  margin-left: 40px;
  margin-top: 120px;
}

.commany-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.suggest {
  height: 980px;
  background-color: rgb(28, 31, 43);
  color: #fff;
  padding: 0 300px;
  background-image: url(@/assets/product/bg1.png);
  background-size: cover;
}

.suggest_h {
  display: flex;
  height: 60px;
  align-items: center;
  margin-top: 106px;
  margin-bottom: 0;
}

.suggest_h_en p {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-bottom: 0;
  margin-left: 10px;
}

.product-eg {
  margin-top: 200px;
  text-align: center;
}

.eg {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gutter-example :deep(.ant-row>div) {
  background: transparent;
  border: 0;
}

.flex_end {
  display: flex;
  justify-content: end;
}

.hover {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all .3s cubic-bezier(.31, .61, .25, .99);
  transition: all .3s cubic-bezier(.31, .61, .25, .99);
}

.hover_top .img {
  background-image: url(@/assets/img/six.png);
  background-size: cover;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover_top .img img {
  width: 50%;
  height: 50%;
}

.hover_top {
  -webkit-transition: all .3s cubic-bezier(.31, .61, .25, .99);
  transition: all .3s cubic-bezier(.31, .61, .25, .99);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hover_top p {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.hover p {
  border-radius: 130px;
}

.hover p:hover {
  border: 1px solid rgba(51, 194, 255, 1) !important;
  color: rgba(51, 194, 255, 1);
}

.eg:hover .hover_top {

  /* transition: 3s steps(3); */
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.eg:hover .hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

.eg:hover .hover_top .img {
  background-image: url(@/assets/img/six1.png);
}

.gutter-box {
  padding: 20px;
}

/* 新闻部分 */
.news {
  color: #fff;
  height: 800px;
  padding: 0 300px;
  background-color: rgba(28, 31, 43, 1);
  min-width: 1280px;
}

.news p {
  text-align: justify;
}

.news_title {
  margin-top: 160px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.news_h {
  display: flex;
  height: 60px;
  align-items: center;
  margin-bottom: 0;
}

.news_h_en p {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-bottom: 0;
  height: 16px;
  margin-left: 10px;
}

.news_more {
  width: 340px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.news_more p {
  margin: 0;
}

.news_img {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.img-box {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 350px;
  overflow: hidden;
}

.img-box img {
  width: 100%;
  object-fit: contain;
}

.divider {
  margin-top: 17px;
  background: rgba(255, 255, 255, 1);
}

.ant-divider-horizontal {
  margin-bottom: 17px;
}

.news-content {
  margin-left: 20px;
  width: 340px
}

.news-content p {
  margin: 0;
}

.news-content .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.news-content .news-detail {
  cursor: pointer;
}

.news-content .news-detail p:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  height: 38px;

}

.news-content .news-detail p:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
  height: 22px;
}

.news-content .news-detail .right {
  display: flex;
  justify-content: end;

}

.news-content .news-detail p:nth-child(3) {
  font-size: 18px;
  font-weight: 900;
  height: 27px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.news-content .news-detail p:nth-child(4) {
  font-size: 14px;
  font-weight: 800;
  height: 23px;
  margin-bottom: 10px;
  overflow: hidden;
}

.news-detail:nth-child(2) .divider {
  display: none;
}

.news-detail .info {
  font-size: 12px;
}
</style>
