<template>
    <div id="home" class="home">
        <a-row>
            <a-col :span="24">
                <div style="height: 680px;"><img src="@/assets/img/header_image_3.png" style="width: 100%;height: 100%;">
                </div>
            </a-col>
        </a-row>
    </div>
    <div class="desc" id="content1">
        <div class="news-content ">
            <a-row style="height: 100%;">
                <a-col :span="14">
                    <div class="article-left">
                        <div class="title">{{ detail.title }}</div>
                        <div class="title-desc">
                            <p>发布时间：{{ detail.date }}</p>
                            <p>信息来源：忆伯科技</p>
                        </div>
                    </div>
                    <a-divider style="background-color:  rgba(255, 255, 255, 1);"></a-divider>
                    <div v-if="detail.cover" class="" style="width:100%; height: 300px;">
                        <img :src=detail.cover alt="" style="width:100%;height: 100% ;object-fit: contain;">
                    </div>
                    <div class="detail-content">
                        <div v-html=detail.content>
                        </div>
                    </div>
                </a-col>
                <a-col :span="1">
                </a-col>
                <a-col :span="9">
                    <div class="news-tui">
                        <div class="tui-zh">推荐新闻</div>
                        <div class="tui-en">
                            <div>RECOMMEND</div>
                            <div>NEWS</div>
                        </div>
                    </div>
                    <div class="tui-list">
                        <div class="tui-item" v-for="(item, index) in slideList" :key="index" @click="showDetail(item.id)">
                            <img :src="item.cover" alt="" style="width: 440px;height: 250px;object-fit: contain;">
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.des }}</p>
                            <div class="right" @click="showDetail(item.id)">
                                <arrow-right-outlined :style="{ fontSize: '24px' }" />
                            </div>
                            <a-divider style="background-color:  rgba(255, 255, 255, 1);"></a-divider>
                        </div>
                    </div>
                    <div class="foot">
                        <div class="page">
                            <div class="page-item">
                                <div class="page-pre" @click="showDetail(detail.id - 1)" v-show="detail.id - 1 > 0">
                                    上一篇
                                </div>
                            </div>
                            <div class="page-item">
                                <div class="page-next" @click="showDetail(detail.id + 1)"
                                    v-show="detail.id + 1 <= list.length">
                                    下一篇
                                </div>
                            </div>
                        </div>
                        <div class="back" @click="back()">
                            返回列表
                        </div>
                    </div>
                </a-col>
            </a-row>

        </div>

    </div>
</template>
 
<script>
import { defineComponent } from 'vue';
import { ArrowRightOutlined } from '@ant-design/icons-vue';
import news from "@/api/test";
import { useRoute } from 'vue-router'
export default defineComponent({
    components: {
        ArrowRightOutlined,
    },
    name: "NewsDetail",
    data() {
        return {
            slideList: news.slice(0, 2),
            list: news,
            id: 0,
            detail: "",
        }
    },
    created() {
        let that = this
        const route = useRoute();
        if (route.query.id) {
            let id = route.query.id
            that.list.forEach(item => {
                if (item.id == parseInt(id)) {
                    that.detail = item
                }
            });
        }
    },
    mounted() {
        this.goAnchor()
    },
    methods: {
        showDetail(id) {
            let that = this
            that.goAnchor()
            that.list.forEach(item => {

                if (item.id == parseInt(id)) {
                    that.detail = item
                }
            });
        },
        back() {
            this.$router.push({
                path: "/news",
            })
        },
        goAnchor() {/*参数selector是id选择器（#anchor14）*/
            document.getElementById("content1").scrollIntoView()
        },

    },
})
</script>

<style>
.home {
    height: 680px;
    background-color: rgba(104, 110, 130, 1);
    width: 100%;
    min-width: 1280px;
}
</style>
<style scoped>
.desc {

    padding: 0 300px;
    min-width: 1280px;
    width: 100%;
    background-color: rgba(28, 31, 43, 1);
    display: flex;
    justify-content: center;
}

.news {
    width: 100%;
    height: 298px;
    background-color: rgba(41, 41, 41, 1);
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    margin-right: 40px;
    border: 1px solid #161922;
    color: rgba(255, 255, 255, 1);
    padding: 0 55px;
}

.news-content {
    margin-top: 110px;
    width: 100%;
    color: #fff;
}

.article-left {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.article-left .title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.article-left .title-desc {
    margin-top: 51px;
    display: flex;
    width: 380px;
    justify-content: space-between;
}

.article-left .title-desc p {
    margin: 0;
}

.news-tui {
    display: flex;
    height: 50px;

}

.news-tui .tui-zh {
    font-size: 32px;
    font-weight: 700;
}

.news-tui .tui-en {
    font-weight: 600;
    margin-left: 10px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tui-list {
    margin-top: 100px;
    color: #fff;
    height: 800px;
    width: 440px;
    overflow-y: hidden;

}

.foot {
    width: 440px;
    height: 240px;
    margin-top: 50px;
}

.foot .page {
    display: flex;
    justify-content: space-between;
}

.foot .page .page-item {
    width: 50%;
}

.foot .page .page-item:first-child {
    display: flex;
    justify-content: start;
}

.foot .page .page-item:last-child {
    display: flex;
    justify-content: end;
}

.foot .page .page-pre {
    cursor: pointer;
    width: 160px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(@/assets/img/left.png);
    background-size: cover;
}

.foot .page .page-next {
    cursor: pointer;
    width: 160px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(@/assets/img/more-detail.png);
    background-size: cover;
}

.foot .page .page-next:hover {
    color: #000;
    background-image: url(@/assets/img/more-detail-w.png) !important;
}

.foot .page .page-pre:hover {
    color: #000;
    background-image: url(@/assets/img/left-w.png) !important;
}

.foot .back {
    margin-top: 50px;
    cursor: pointer;
    margin-bottom: 50px;
    width: 440px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
}

.tui-item {
    cursor: pointer;
}

.tui-item h3 {
    color: #fff;
    margin-top: 20px;
}

.detail-content p {
    margin-top: 10px;
}

.right {
    cursor: pointer;
}

.detail-content {
    margin-top: 20px;
    color: #fff;
    margin-bottom: 140px;
}
:deep(.detail-content) h3 {
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}
</style>